import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
			shopid:null,
			OAid:null,
			Medid:null,
			abtab:null,
			prepayid:null,
			Invitationcode:null,
			Phonepaydata:{}
    },
    getters: {
        
    },
    mutations: {
			shopidfun(state,data){
				state.shopid=data
			},
			OAidfun(state,data){
				state.OAid=data
			},
			Medidfun(state,data){
				state.Medid=data
			},
			abtabfun(state,data){
				state.abtab=data
			},
			prepayidfun(state,data){
				state.prepayid=data
			},
			Invitationcodefun(state,data){
				state.Invitationcode=data
			},
			Phonepaydatafun(state,data){
				state.Phonepaydata=data
			}
    },
    actions: {
   

    },

    plugins: [
        persistedState({
         storage: window.sessionStorage
        })
       ]

})

export default store