<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
	.el-tabs__nav-next, .el-tabs__nav-prev {
		line-height: 37px!important;
		font-size: 24px!important;
	}
	::-webkit-scrollbar{width:0px;height:10px}
</style>
<script>
const store = require("../vuex/store");
export default {
	data () {
		return {
			To:{}
		}
	},
	created () {
	},
	 
}
</script>