import Vue from 'vue';
import AMap from 'vue-amap';
import App from './layouts/App.vue';
import router from './router';
// import store from './store';
import ElementUI from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css';
import IconSvg from './components/icon-components.vue';
import store from './vuex/store'
import moment from 'moment'
import less from 'less'
import LuckDraw from 'vue-luck-draw'
import Video from 'video.js'
import 'video.js/dist/video-js.css'

import Viewer from 'v-viewer' //放大图片
import 'viewerjs/dist/viewer.css' //放大图片
import './assets/font/iconfont.js' //引入阿里巴巴图标库js
import './assets/font/iconfont.css' //引入阿里巴巴图标库css
// 引入jQuery、bootstrap
import $ from 'jquery'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap/dist/js/bootstrap.min.js'

Vue.prototype.$video = Video;

Vue.use(LuckDraw)
Vue.use(less)
// import Less  from 'Less';
Vue.prototype.$ = $
Vue.use(AMap)
Vue.use(Viewer);

Vue.prototype.$axios = axios
axios.defaults.baseURL = "http://rhdgjfwapi.ronghengzn.com"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
Vue.prototype.$addr = axios;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;//赋值使用
Vue.component('icon-svg', IconSvg);

Vue.use(ElementUI);
Vue.config.productionTip = false;
// Vue.prototype.SetSTime = function(Time){
//   let STime = new Date(Time);
//   return  STime.getFullYear() + '-' + (STime.getMonth() + 1) + '-' + STime.getDate() + ' ' + STime.getHours() +':' + STime.getMinutes() + ':' + STime.getSeconds();
// }
// Vue.prototype.SetTime = function(Time){
//   const d = new Date(Time)
//   return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
// }
Viewer.setDefaults({//放大图片参数
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});
router.afterEach((to, from) => {
    window.scrollTo(0, 0);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
